.nav-tabs {
  .nav-link {
    color: $nav-tabs-link-color;
    &:hover {
      cursor: pointer;
    }
  }
}

.tab-content {
  margin-top: -1px;
  background: $tab-content-bg;
  border: 1px solid $border-color;
  @include border-radius(0 0 $border-radius $border-radius);

  .tab-pane {
    padding: $spacer;
  }
}

.card-block {
  .tab-content {
    margin-top: 0;
    border: 0;
  }
}

.nav-fill {
  .nav-link {
    background-color: #fff;
    border-color: $border-color;
  }
  .nav-link + .nav-link {
    margin-left: -1px;
  }
  .nav-link.active {
    margin-top: -1px;
    // margin-left: 0;
    border-top: 2px solid $primary;
  }
}

// Right-to-Left Support
*[dir="rtl"] {
  .nav {
    padding-right: 0;
  }
}
